import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql} from 'gatsby'

import '../styles/default.css'
import './layout.scss'

const Main = ({ children }) => {
const [isIframe, setIsIframe] = useState(false)

  useLayoutEffect(() => {
    if ( typeof window !== 'undefined' && window !== window.top ){
      setIsIframe(true)
    } else setIsIframe(false)
  }, [])

  return (
    !isIframe
      ? <>
          <div className='main-wrap'>{children}</div>
        </>
      : <h1>IFrame с нашего сайта https://justice-it.ru/ использовать запрещено</h1>

  )
}

const Layout = ({children}) => {

  const [isAlert, setIsAlert] = useState(true)

  const Alert = () => (
    <div className='alert'>
      <div className='block'>
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M19.6012 9.22984C18.3147 8.76785 17.5235 7.45867 17.6229 6.42489C17.6229 6.25493 17.5211 6.097 17.3923 5.98544C17.2636 5.87442 17.0639 5.82638 16.8968 5.85325C14.9364 6.14087 13.1413 4.70669 12.9634 2.69068C12.9474 2.50873 12.8479 2.34506 12.6934 2.24721C12.5395 2.14994 12.3477 2.12877 12.1778 2.19229C10.5888 2.7742 9.08734 1.72202 8.48824 0.351004C8.37609 0.0946378 8.09801 -0.0466898 7.82391 0.013974C3.31324 1.02049 0 5.3772 0 10C0 15.4926 4.50742 20 10 20C15.4926 20 20 15.4926 20 10C19.9837 9.65105 19.9813 9.36601 19.6012 9.22984ZM4.11133 10.6301C3.78773 10.6301 3.52539 10.3677 3.52539 10.0441C3.52539 9.72046 3.78773 9.4582 4.11133 9.4582C4.43492 9.4582 4.69727 9.72046 4.69727 10.0441C4.69727 10.3677 4.43488 10.6301 4.11133 10.6301ZM6.48438 15.2735C5.51508 15.2735 4.72656 14.4849 4.72656 13.5156C4.72656 12.5464 5.51508 11.7578 6.48438 11.7578C7.45367 11.7578 8.24219 12.5464 8.24219 13.5156C8.24219 14.4849 7.45371 15.2735 6.48438 15.2735ZM7.07031 8.24223C6.4243 8.24223 5.89844 7.71637 5.89844 7.07036C5.89844 6.42434 6.4243 5.89849 7.07031 5.89849C7.71633 5.89849 8.24219 6.42434 8.24219 7.07036C8.24219 7.71637 7.71633 8.24223 7.07031 8.24223ZM11.7578 16.4453C11.1118 16.4453 10.5859 15.9195 10.5859 15.2735C10.5859 14.6274 11.1118 14.1016 11.7578 14.1016C12.4038 14.1016 12.9297 14.6274 12.9297 15.2735C12.9297 15.9195 12.4038 16.4453 11.7578 16.4453ZM12.3438 11.7578C11.3745 11.7578 10.5859 10.9693 10.5859 10C10.5859 9.03074 11.3745 8.24223 12.3438 8.24223C13.313 8.24223 14.1016 9.03074 14.1016 10C14.1016 10.9693 13.3131 11.7578 12.3438 11.7578ZM15.8301 12.9738C15.5065 12.9738 15.2441 12.7115 15.2441 12.3879C15.2441 12.0642 15.5065 11.8019 15.8301 11.8019C16.1537 11.8019 16.416 12.0642 16.416 12.3879C16.416 12.7115 16.1536 12.9738 15.8301 12.9738Z'
            fill='#323232'
          />
        </svg>
        <div className='alertText'>
          Мы используем cookies
        </div>
      </div>
      <button className='okButton' onClick={() => handleSetCookies()}>Хорошо</button>
    </div>
  )

  const cookiesAlert = () => {
    const checkLocalStorage =
      typeof window !== 'undefined' &&
      localStorage?.getItem('cookiesTracking') === 'true'
    if (isAlert && !checkLocalStorage) {
      return <Alert/>
    } else {
      return null
    }
  }

  const handleSetCookies = () => {
    localStorage.setItem('cookiesTracking', 'true')
    setIsAlert(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <div className='container'>{cookiesAlert()}</div>
          <Main>{children}</Main>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
