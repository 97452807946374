import React, { createContext, useReducer } from 'react'

export const ThemeContext = createContext(null)
const systemTheme = typeof window !== 'undefined' && window.matchMedia('(prefers-color-scheme: dark)').matches
const stateThemeLocal = typeof window !== 'undefined' && localStorage.getItem('theme')
const stateTheme = stateThemeLocal === 'dark'
const initialState = { darkMode: stateTheme || systemTheme }

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'LIGHTMODE':
      return { darkMode: false }
    case 'DARKMODE':
      return { darkMode: true }
    default:
      return state
  }
}

export const ThemeProvider = (props) => {
  const [state, dispatch] = useReducer(themeReducer, initialState)

  return <ThemeContext.Provider value={{ state: state, dispatch: dispatch }}>{props.children}</ThemeContext.Provider>
}
