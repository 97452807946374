module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Justice | Web development company","short_name":"Justice","start_url":"/","background_color":"#f3f3f3","theme_color":"#f3f3f3","display":"minimal-ui","icon":"src/assets/images/icon/icon-512x512.png","icons":[{"src":"src/assets/images/icon/icon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/images/icon/icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/images/icon/icon-70x70.png","sizes":"70x70","type":"image/png"},{"src":"src/assets/images/icon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/assets/images/icon/icon-150x150.png","sizes":"150x150","type":"image/png"},{"src":"src/assets/images/icon/icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/images/icon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/icon/icon-310x310.png","sizes":"310x310","type":"image/png"},{"src":"src/assets/images/icon/icon-310x150.png","sizes":"310x150","type":"image/png"},{"src":"src/assets/images/icon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f181263bfe2345ad7521df305a0ca7d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://justice-it.ru"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-230515157-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"88962616","webvisor":true,"trackHash":true,"afterBody":true,"defer":true,"useCDN":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
