import React from 'react'

import { ThemeProvider } from './src/helpers/contextProvider/themeContext'

 export const wrapRootElement = ({ element }) => {
 return (
   <>
     <ThemeProvider>
      {element}
     </ThemeProvider>
   </>
  )
 }
